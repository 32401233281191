<template>
  <div>
    <headers />
    <div class="mb row" style="padding:15px;padding-top: 90px !important;">
      <div class="col-12">
        <div class="tab-box">
          <ul class="tab-head nav nav-tabs">
            <li
              class="nav-item"
              data-id="tc1"
              @click="tab('tc1')"
              v-bind:class="[curindex=='tc1' ? 'nav-item active show':'nav-item']"
            >
              <a data-toggle="tab" class="nav-link" aria-expanded="true">
                <div class="lang" v-html="lan.lease_info"></div>
              </a>
            </li>
            <li
              class="nav-item"
              data-id="tc2"
              @click="tab('tc2')"
              v-bind:class="[curindex=='tc2' ? 'nav-item active show':'nav-item']"
            >
              <a data-toggle="tab" class="nav-link" aria-expanded="true">
                <div class="lang" v-html="lan.building_info"></div>
              </a>
            </li>
            <li
              class="nav-item"
              data-id="tc3"
              @click="tab('tc3')"
              v-bind:class="[curindex=='tc3' ? 'nav-item active show':'nav-item']"
            >
              <a data-toggle="tab" class="nav-link" aria-expanded="true">
                <div class="lang" v-html="lan.leasecontact_info"></div>
              </a>
            </li>
            <li
              class="nav-item"
              data-id="tc4"
              @click="tab('tc4')"
              v-bind:class="[curindex=='tc4' ? 'nav-item active show':'nav-item']"
            >
              <a data-toggle="tab" class="nav-link" aria-expanded="true">
                <div class="lang" v-html="lan.market_data"></div>
              </a>
            </li>
            <li
              class="nav-item"
              data-id="tc5"
              @click="tab('tc5')"
              v-bind:class="[curindex=='tc5' ? 'nav-item active show':'nav-item']"
            >
              <a data-toggle="tab" class="nav-link" aria-expanded="true">
                <div class="lang" v-html="lan.ext_tab1"></div>
                <div class="lang" v-html="lan.demo"></div>
              </a>
            </li>
            <li
              class="nav-item"
              data-id="tc6"
              @click="tab('tc6')"
              v-bind:class="[curindex=='tc6' ? 'nav-item active show':'nav-item']"
            >
              <a data-toggle="tab" class="nav-link" aria-expanded="true">
                <div class="lang" v-html="lan.ext_tab2"></div>
                <div class="lang" v-html="lan.demo"></div>
              </a>
            </li>
            <li
              class="nav-item"
              data-id="tc7"
              @click="tab('tc7')"
              v-bind:class="[curindex=='tc7' ? 'nav-item active show':'nav-item']"
            >
              <a data-toggle="tab" class="nav-link" aria-expanded="true">
                <div class="lang" v-html="lan.ext_tab3"></div>
                <div class="lang" v-html="lan.demo"></div>
              </a>
            </li>
            <li
              class="nav-item"
              data-id="tc8"
              @click="tab('tc8')"
              v-bind:class="[curindex=='tc8' ? 'nav-item active show':'nav-item']"
            >
              <a data-toggle="tab" class="nav-link" aria-expanded="true">
                <div class="lang" v-html="lan.ext_tab4"></div>
                <div class="lang" v-html="lan.demo"></div>
              </a>
            </li>
            <li
              class="nav-item"
              data-id="tc9"
              @click="tab('tc9')"
              v-bind:class="[curindex=='tc9' ? 'nav-item active show':'nav-item']"
            >
              <a data-toggle="tab" class="nav-link" aria-expanded="true">
                <div class="lang" v-html="lan.ext_tab5"></div>
                <div class="lang" v-html="lan.demo"></div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="tab-content" style="width:100%;background:#fff">
        <h4 class="header-title">{{ paramName }}</h4>
        <router-link
          :to="{path:tolink}"
          class="btn btn-primary waves-effect waves-light btn-back-fixed"
        >
          <i class="mdi mdi-arrow-left-bold"></i> {{$language('一覧')}}
        </router-link>
        <div
          class="embed-responsive-16by9 tab-pane show active tc2"
          id="tc5"
          v-if="curindex=='tc5'"
        >
          <div class="col-12">
            <div class="card-box">
              <div class="lang" v-html="lan.ext_tab1_content"></div>
            </div>
          </div>
        </div>
        <div
          class="embed-responsive-16by9 tab-pane show active tc2"
          id="tc6"
          v-if="curindex=='tc6'"
        >
          <div class="col-12">
            <div class="card-box">
              <div class="row" style="width:80%;margin:5px auto;">
                <div class="col-6 piclist">
                  <carousel :items-to-show="1" v-if="BuildingPic_to.length>0">
                    <slide v-for="(item,slide) in BuildingPic_to" :key="slide">
                      <img :src="item" />
                    </slide>

                    <template #addons>
                      <navigation />
                      <pagination />
                    </template>
                  </carousel>
                </div>
                <div class="col-6">
                  <div class="tab-box">
                    <ul class="tab-head nav nav-tabs">
                      <li
                        class="nav-item"
                        data-id="tc1"
                        @click="tab2_to('tc1')"
                        v-bind:class="[bcurindex_to=='tc1' ? 'nav-item active show':'nav-item']"
                      >
                        <a data-toggle="tab" class="nav-link" aria-expanded="true">
                          <div class="lang" v-html="lan.building_base"></div>
                        </a>
                      </li>
                      <li
                        class="nav-item"
                        data-id="tc2"
                        @click="tab2_to('tc2')"
                        v-bind:class="[bcurindex_to=='tc2' ? 'nav-item active show':'nav-item']"
                      >
                        <a data-toggle="tab" class="nav-link" aria-expanded="true">
                          <div class="lang" v-html="lan.building_ext"></div>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-content" style="width:100%;background:#fff">
                    <div
                      class="embed-responsive-16by9 tab-pane show active tc1"
                      v-if="bcurindex_to=='tc1'"
                    >
                      <div class="table-rep-plugin">
                        <div class data-pattern="priority-columns">
                          <table class="table table-borderless">
                            <tbody>
                              <tr
                                v-for="(value, keyName) in BuildingInfo_to"
                                :key="keyName"
                                v-show="checkKeys.includes(keyName)"
                              >
                                <td
                                  v-if="keyName=='PublicTransportationMetro_1'"
                                >{{lan['PublicTransportationMetro']}}</td>
                                <td v-if="keyName=='FlrsAbvGrnd'">{{lan['FlrsAbvBlwGrnd']}}</td>

                                <td
                                  v-if="keyName!='FlrsAbvGrnd' && keyName!='PublicTransportationMetro_1'"
                                >{{lan[keyName]}}</td>
                                <th v-if="keyName=='PublicTransportationMetro_1'">
                                  {{BuildingInfo_to['PublicTransportationMetro_1']}} {{(BuildingInfo_to['building_ext']['最寄り駅徒歩時間(分)1']!=undefined && BuildingInfo_to['building_ext']['最寄り駅徒歩時間(分)1']!=null ? '徒歩'+BuildingInfo_to['building_ext']['最寄り駅徒歩時間(分)1']+'分':'')}}
                                  <br />
                                  {{BuildingInfo_to['PublicTransportationMetro_2']}} {{(BuildingInfo_to['building_ext']['最寄り駅徒歩時間(分)2']!=undefined && BuildingInfo_to['building_ext']['最寄り駅徒歩時間(分)2']!=null ? '徒歩'+BuildingInfo_to['building_ext']['最寄り駅徒歩時間(分)2']+'分':'')}}
                                  <br />
                                  {{BuildingInfo_to['PublicTransportationMetro_3']}} {{(BuildingInfo_to['building_ext']['最寄り駅徒歩時間(分)3']!=undefined && BuildingInfo_to['building_ext']['最寄り駅徒歩時間(分)3']!=null ? '徒歩'+BuildingInfo_to['building_ext']['最寄り駅徒歩時間(分)3']+'分':'')}}
                                </th>
                                <th
                                  v-if="keyName=='Typical_Minimum_FloorSize'"
                                >{{formart_money(BuildingInfo_to['Typical_Minimum_FloorSize'])}}坪 / {{formart_money(BuildingInfo_to['Typical_Minimum_FloorSize_SqM'])}}m2</th>
                                <th
                                  v-if="keyName=='TotalGFA_SqM'"
                                >{{formart_money(BuildingInfo_to['TotalGFA'])}}坪 / {{formart_money(BuildingInfo_to['TotalGFA_SqM'])}}m2</th>
                                <th
                                  v-if="keyName=='FlrsAbvGrnd'"
                                >{{lan['FlrsAbvBlwGrndValue'].replace('{abv}',BuildingInfo_to['FlrsAbvGrnd']).replace('{blw}',BuildingInfo_to['FlrsBlwGrnd'])}}</th>
                                <th
                                  v-if="keyName!='FlrsAbvGrnd' && keyName!='TotalGFA_SqM' && keyName!='Typical_Minimum_FloorSize' && keyName!='PublicTransportationMetro_1'"
                                >{{formart_date(value)+(keyName=='BuildingName'?'('+BuildingInfo_to.BuildingID+')':'' )}}</th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- end .table-responsive -->
                      </div>
                    </div>
                    <div
                      class="embed-responsive-16by9 tab-pane show active tc2"
                      v-if="bcurindex_to=='tc2'"
                    >
                      <div class="table-rep-plugin">
                        <div class data-pattern="priority-columns">
                          <table class="table table-borderless">
                            <tbody>
                              <tr
                                v-for="(value, keyName) in BuildingInfo_to.building_ext"
                                v-show="!checkKeys_ext.includes(keyName)"
                                :key="keyName"
                              >
                                <td>{{keyName}}</td>
                                <th>{{value}}{{(keyName=='エレベーター数' ?'基':'')}}</th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- end .table-responsive -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <google-map
                v-if="!isLoading"
                :address="BuildingInfo_to.Address"
                :reload="reloadCount_to"
              />
              <div class="row" v-else>
                <div class="col-6">
                  {{$language('google_map')}}
                  <div class="page-title-box">
                    <h4 class="page-title">{{$language('google_map')}}</h4>
                  </div>
                  <div class="card-box mb-2">
                    <!-- map -->
                    <div class="d-flex justify-content-center p-5">
                      <div class="spinner-border m-12" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="page-title-box">
                    <h4 class="page-title">{{$language('google_street_view')}}</h4>
                  </div>
                  <div class="card-box mb-2">
                    <!-- streetview -->
                    <div class="d-flex justify-content-center p-5">
                      <div class="spinner-border m-12" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="page-title-box">
                    <h4 class="page-title">{{$language('rader_chart')}}</h4>
                  </div>
                  <div class="card-box mb-2">
                    <div class>
                      <apexchart
                        type="radar"
                        height="500px"
                        width="100%"
                        :options="options_to"
                        :series="series_to"
                      ></apexchart>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="page-title-box">
                    <h4 class="page-title">{{$language('物件の特徴')}}</h4>
                  </div>
                  <div class="card-box mb-2">
                    <!-- rangeslider -->
                    <div v-if="!isLoading4">
                      <div class="mb-3" v-for="(obj, num) in rangeKeys_to" :key="num">
                        <div v-if="jsonLang[lang][obj]">
                          <h4 class="header-title mb-1">{{ jsonLang[lang][obj] }}</h4>
                          <range-slider
                            :id="obj"
                            :from="extendParams_to.extended[obj]"
                            @updateRange="UpdateRange_to"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center p-5" v-else>
                      <div class="spinner-border m-12" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="page-title-box">
                    <h4 class="page-title">
                      {{$language('他の人のコメント')}}
                      <span>({{extendParams_to.extended_memo_others.length}})</span>
                    </h4>
                  </div>
                  <div class="card-box mb-2">
                    <!-- other comments -->
                    <div v-if="!isLoading4">
                      <div class="carousel" v-if="extendParams_to.extended_memo_others.length > 0">
                        <div class="d-flex other-comments-container">
                          <button
                            type="button"
                            class="btn btn-light other-control other-control-prev"
                            @click.prevent.stop="OtherComments('prev')"
                            v-if="extendParams.extended_memo_others.length > 1"
                          >
                            <i class="mdi mdi-chevron-double-left" aria-hidden="true"></i>
                          </button>
                          <div class="other-item card-body" :id="'memo-' + otherCount">
                            <div
                              v-if="extendParams.extended_memo_others[otherCount].memo"
                              v-html="extendParams.extended_memo_others[otherCount].memo"
                            ></div>
                            <div v-else class="text-secondary">コメント未入力</div>
                            <span
                              style="color:#ccc;position:absolute;right:5px;bottom:5px;"
                            >日付：{{extendParams.extended_memo_others[otherCount].updated_at}}</span>
                          </div>
                          <button
                            type="button"
                            class="btn btn-light other-control other-control-next"
                            @click.prevent.stop="OtherComments('next')"
                            v-if="extendParams.extended_memo_others.length > 1"
                          >
                            <i class="mdi mdi-chevron-double-right" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>
                      <div v-else>
                        <p class="text-muted">{{$language('コメントがありません')}}</p>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center p-5" v-else>
                      <div class="spinner-border m-12" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="page-title-box">
                    <h4 class="page-title">{{$language('コメント')}}</h4>
                  </div>
                  <div class="card-box mb-2">
                    <!-- comment -->
                    <div v-if="!isLoading4">
                      <textarea
                        class="form-control"
                        rows="10"
                        v-model="extendParams_to.extended.memo"
                      ></textarea>
                    </div>
                    <div class="d-flex justify-content-center p-5" v-else>
                      <div class="spinner-border m-12" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div
                  class="col-12 btn-toolbar justify-content-end align-items-center row pr-0 pl-3"
                >
                  <div
                    class="col-11 mb-0 alert alert-primary"
                    v-if="saveAlert === 1"
                  >{{$language('ご意見有難う御座います。内容を確認してシステム上に反映します。')}}</div>
                  <div class="col-11 mb-0 alert alert-danger" v-if="saveAlert === 9">{{$language('保存に失敗しました')}}</div>
                  <div class="col-1 pr-0">
                    <button type="button" class="btn btn-primary" @click="SaveParams_to">{{$language('save')}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="embed-responsive-16by9 tab-pane show active tc2"
          id="tc7"
          v-if="curindex=='tc7'"
        >
          <div class="col-12">
            <div class="card-box">
              <div class="lang" v-html="lan.ext_tab3_content"></div>
            </div>
          </div>
        </div>
        <div
          class="embed-responsive-16by9 tab-pane show active tc2"
          id="tc8"
          v-if="curindex=='tc8'"
        >
          <div class="col-12">
            <div class="card-box">
              <div class="lang" v-html="lan.ext_tab4_content"></div>
            </div>
          </div>
        </div>
        <div
          class="embed-responsive-16by9 tab-pane show active tc2"
          id="tc9"
          v-if="curindex=='tc9'"
        >
          <div class="col-12">
            <div class="card-box">
              <div class="lang" v-html="lan.ext_tab5_content"></div>
            </div>
          </div>
        </div>
        <div class="embed-responsive-16by9 tab-pane show active tc1" v-if="curindex=='tc1'">
          <div class="col-12">
            <div class="card-box">
              <div class="table-rep-plugin">
                <div class="responsive-table-plugin" data-pattern="priority-columns">
                  <table class="table table-striped table-borderless">
                    <tbody>
                      <template v-for="(value, keyName) in LeaseInfo">
                        <tr
                          :key="keyName"
                          v-if="keyName!='columns' && keyName!='columnsContact' && LeaseInfo['columns'][keyName]!=undefined && LeaseInfo['columns'][keyName]!='' && LeaseInfo['columns'][keyName].indexOf('不要')==-1"
                        >
                          <th>{{LeaseInfo['columns'][keyName]}}</th>
                          <td>{{value}}</td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
                <!-- end .table-responsive -->
              </div>
            </div>
          </div>
        </div>
        <div class="embed-responsive-16by9 tab-pane show active tc4" v-if="curindex=='tc4'">
          <div class="p-2" v-if="chart_data.chart_nds==undefined">
            <div class="lang" v-html="lan.no_data"></div>
          </div>
          <div v-if="chart_data.chart_nds!=undefined">
            <h4>{{area_name_jp}}</h4>
            <div class="tab-box">
              <ul class="tab-head nav nav-tabs">
                <li
                  class="nav-item"
                  data-id="tc1"
                  @click="tab3('tc1')"
                  v-bind:class="[ccurindex=='tc1' ? 'nav-item active show':'nav-item']"
                >
                  <a data-toggle="tab" class="nav-link" aria-expanded="true">
                    <div class="lang" v-html="lan.building_base"></div>
                  </a>
                </li>
                <li
                  class="nav-item"
                  data-id="tc6"
                  @click="tab3('tc6')"
                  v-bind:class="[ccurindex=='tc6' ? 'nav-item active show':'nav-item']"
                >
                  <a data-toggle="tab" class="nav-link" aria-expanded="true">
                    <div class="lang" v-html="lan.face_new_average"></div>
                  </a>
                </li>
                <li
                  class="nav-item"
                  data-id="tc2"
                  @click="tab3('tc2')"
                  v-bind:class="[ccurindex=='tc2' ? 'nav-item active show':'nav-item']"
                >
                  <a data-toggle="tab" class="nav-link" aria-expanded="true">
                    <div class="lang" v-html="lan.vacancy_rate"></div>
                  </a>
                </li>
                <li
                  class="nav-item"
                  data-id="tc2"
                  @click="tab3('tc3')"
                  v-bind:class="[ccurindex=='tc3' ? 'nav-item active show':'nav-item']"
                >
                  <a data-toggle="tab" class="nav-link" aria-expanded="true">
                    <div class="lang" v-html="lan.new_supply_net_absorption"></div>
                  </a>
                </li>
                <li
                  class="nav-item"
                  data-id="tc2"
                  @click="tab3('tc4')"
                  v-bind:class="[ccurindex=='tc4' ? 'nav-item active show':'nav-item']"
                >
                  <a data-toggle="tab" class="nav-link" aria-expanded="true">
                    <div class="lang" v-html="lan.major_new_supply_projects"></div>
                  </a>
                </li>
                <li
                  class="nav-item"
                  data-id="tc2"
                  @click="tab3('tc5')"
                  v-bind:class="[ccurindex=='tc5' ? 'nav-item active show':'nav-item']"
                >
                  <a data-toggle="tab" class="nav-link" aria-expanded="true">
                    <div class="lang" v-html="lan.major_future_projects"></div>
                  </a>
                </li>
              </ul>
              <div class="help">
                <img :src="'../img/help.svg'" @click="isPopupHelp=true" />
              </div>
              <transition name="fade">
                <div v-show="isPopupHelp" class="modal popup-modal">
                  <div class="modal-dialog">
                    <div class="popup modal-content help-box">
                      <div class="modal-header">
                        <button type="button" class="close" @click="isPopupHelp = false">
                          <span>×</span>
                        </button>
                      </div>
                      <div class="modal-body" v-if="isPopupHelp">
                        <div class="lang" v-html="lan.help_chart"></div>
                      </div>
                    </div>
                  </div>
                  <div class="popup-bg" @click="isPopupHelp = false"></div>
                </div>
              </transition>
            </div>
            <div class="tab-content" style="padding: 15px;background:#fff">
              <div class="embed-responsive-16by9 tab-pane show active tc1" v-if="ccurindex=='tc1'">
                <div class="col-12">
                  <div class="card-box">
                    <div v-html="chart_data.building_area_description"></div>
                  </div>
                </div>
              </div>
              <div class="embed-responsive-16by9 tab-pane show active tc6" v-if="ccurindex=='tc6'">
                <div class="col-12">
                  <div class="card-box">
                    <iframe
                      class="embed-responsive-item"
                      :src="chart_data.chart_rent[cur_lan]"
                      loading="lazy"
                    ></iframe>
                  </div>
                </div>
              </div>
              <div class="embed-responsive-16by9 tab-pane show active tc2" v-if="ccurindex=='tc2'">
                <div class="col-12">
                  <div class="card-box">
                    <iframe
                      class="embed-responsive-item"
                      :src="chart_data.chart_vc[cur_lan]"
                      loading="lazy"
                    ></iframe>
                  </div>
                </div>
              </div>
              <div class="embed-responsive-16by9 tab-pane show active tc3" v-if="ccurindex=='tc3'">
                <div class="col-12">
                  <div class="card-box">
                    <iframe
                      class="embed-responsive-item"
                      :src="chart_data.chart_nds[cur_lan]"
                      loading="lazy"
                    ></iframe>
                  </div>
                </div>
              </div>
              <div class="embed-responsive-16by9 tab-pane show active tc4" v-if="ccurindex=='tc4'">
                <div class="col-12">
                  <div class="card-box">
                    <div class="tab-box">
                      <ul class="tab-head nav nav-tabs">
                        <li class="nav-item active" data-id="mnsp1">
                          <a
                            href="#mnsp1"
                            data-toggle="tab"
                            aria-expanded="true"
                            class="nav-link active"
                          >
                            <div class="lang" v-html="lan.major_new_supply_projects"></div>
                          </a>
                        </li>
                        <li class="nav-item" data-id="mnsp2">
                          <a href="#mnsp2" data-toggle="tab" aria-expanded="false" class="nav-link">
                            <div class="lang" v-html="lan.map"></div>
                          </a>
                        </li>
                      </ul>
                      <div class="tab-content">
                        <div class="embed-responsive tab-pane show active mnsp1" id="mnsp1">
                          <div class="table-responsive2">
                            <table class="table table-striped focus-on">
                              <thead>
                                <tr>
                                  <th style="width:32%">
                                    <div class="lang" v-html="lan.name"></div>
                                  </th>
                                  <th style="width:40%">
                                    <div class="lang" v-html="lan.location"></div>
                                  </th>
                                  <th style="width:16%">
                                    <div class="lang" v-html="lan.completion"></div>
                                  </th>
                                  <th style="width:12%">
                                    <div class="lang" v-html="lan.gfa_"></div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody style="max-height:580px;">
                                <tr class v-for="(obj, idNum) in listDataMNSP" :key="idNum">
                                  <td
                                    :title="idNum2<4 ? obj2:''"
                                    :class="{'hide':(cur_lan!='en' && (idNum2==1 || idNum2==3)) || (cur_lan!='jp' && (idNum2==0 || idNum2==2)) || idNum2==5 || idNum2>6 }"
                                    v-for="(obj2, idNum2) in obj"
                                    :key="idNum2"
                                  >{{(parseFloat(obj2)!=NaN && idNum2==6 ? formart_money(obj2):obj2)}}</td>
                                </tr>
                              </tbody>
                              <tbody v-if="!listDataMNSP.length">
                                <tr class="p-2 no-data">
                                  <td colspan="4">
                                    <code>
                                      <div class="lang" v-html="lan.no_data"></div>
                                    </code>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <span>
                              <div class="lang" v-html="lan.area_unit"></div>
                            </span>
                          </div>
                          <!-- end .table-responsive -->
                        </div>
                        <div class="embed-responsive tab-pane mnsp2" id="mnsp2">
                          <div id="map_mnsp" class="google_maps"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="embed-responsive-16by9 tab-pane show active tc5" v-if="ccurindex=='tc5'">
                <div class="col-12">
                  <div class="card-box">
                    <div class="tab-box">
                      <ul class="tab-head nav nav-tabs">
                        <li class="nav-item active" data-id="mfp1">
                          <a
                            href="#mfp1"
                            data-toggle="tab"
                            aria-expanded="true"
                            class="nav-link active"
                          >
                            <div class="lang" v-html="lan.major_future_projects"></div>
                          </a>
                        </li>
                        <li class="nav-item" data-id="mfp2">
                          <a href="#mfp2" data-toggle="tab" aria-expanded="false" class="nav-link">
                            <div class="lang" v-html="lan.map"></div>
                          </a>
                        </li>
                      </ul>
                      <div class="tab-content">
                        <div class="embed-responsive tab-pane show active mfp1" id="mfp1">
                          <div class="table-responsive2">
                            <table class="table table-striped focus-on">
                              <thead>
                                <tr>
                                  <th style="width:32%">
                                    <div class="lang" v-html="lan.name"></div>
                                  </th>
                                  <th style="width:40%">
                                    <div class="lang" v-html="lan.location"></div>
                                  </th>
                                  <th style="width:16%">
                                    <div class="lang" v-html="lan.completion"></div>
                                  </th>
                                  <th style="width:12%">
                                    <div class="lang" v-html="lan.gfa_"></div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody style="max-height:580px;">
                                <tr class v-for="(obj, idNum) in listDataMFP" :key="idNum">
                                  <td
                                    :title="idNum2<4 ? obj2:''"
                                    :class="{'hide':(cur_lan!='en' && (idNum2==1 || idNum2==3)) || (cur_lan!='jp' && (idNum2==0 || idNum2==2)) || idNum2==5 || idNum2>6 }"
                                    v-for="(obj2, idNum2) in obj"
                                    :key="idNum2"
                                  >{{(parseFloat(obj2)!=NaN && idNum2==6 ? formart_money(obj2):obj2)}}</td>
                                </tr>
                              </tbody>
                              <tbody v-if="!listDataMFP.length">
                                <tr class="p-2 no-data">
                                  <td colspan="4">
                                    <code>
                                      <div class="lang" v-html="lan.no_data"></div>
                                    </code>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <span>
                              <div class="lang" v-html="lan.area_unit"></div>
                            </span>
                          </div>
                          <!-- end .table-responsive -->
                        </div>
                        <div class="embed-responsive tab-pane mfp2" id="mfp2">
                          <div id="map_mfp" class="google_maps"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="embed-responsive-16by9 tab-pane show active tc3" v-if="curindex=='tc3'">
          <div class="col-12">
            <div class="card-box">
              <div
                class="table-rep-plugin"
                v-if="LeaseInfo.contactInfo!=null && LeaseInfo.contactInfo.corp_num!=undefined"
              >
                <div class="responsive-table-plugin" data-pattern="priority-columns">
                  <table class="table table-striped table-borderless">
                    <tbody>
                      <template v-for="(value, keyName) in LeaseInfo.contactInfo">
                        <tr
                          :key="keyName"
                          v-if="LeaseInfo['contactCloumns'][keyName]!=undefined && LeaseInfo['contactCloumns'][keyName]!='' && LeaseInfo['contactCloumns'][keyName].indexOf('不要')==-1 && LeaseInfo['contactCloumns'][keyName]!='会社番号'"
                        >
                          <th>{{LeaseInfo['contactCloumns'][keyName]}}</th>
                          <td
                            v-if="keyName=='corp_num_' &&( value==null || value=='_No_corpNum_' || value!=LeaseInfo.contactInfo['corp_num'])"
                          >
                            {{value}}
                            <a style=" padding-left:20px">
                              ?
                              <div class="lang" v-html="lan.waiting_ok"></div>
                            </a>
                          </td>
                          <td
                            v-else-if="keyName=='corp_num_' &&( value!=null && value==LeaseInfo.contactInfo['corp_num'])"
                            style="text-decoration: underline;"
                          >
                            <router-link
                              :to="'/'+viewParams_company.name+'/100?dataId=0&dataType=100&dataName='+LeaseInfo.contactInfo['Name']+'&code=&dataNumber='+value+'&curindex=tc1&tolink='+curlink"
                              class="btn btn-default"
                            >
                              {{value}}
                              <i class="mdi mdi-arrow-right-bold"></i>
                            </router-link>
                          </td>
                          <td v-else>{{value}}</td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
                <!-- end .table-responsive -->
              </div>
              <div v-if="LeaseInfo.contactInfo==null || LeaseInfo.contactInfo.corp_num==undefined">
                <div class="lang" v-html="lan.no_contact_info"></div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="embed-responsive-16by9 tab-pane show active tc2"
          id="tc2"
          v-if="curindex=='tc2'"
        >
          <div class="col-12">
            <div class="card-box">
              <div class="row" style="width:80%;margin:5px auto;">
                <div class="col-6 piclist">
                  <carousel :items-to-show="1" v-if="BuildingPic.length>0">
                    <slide v-for="(item,slide) in BuildingPic" :key="slide">
                      <img :src="item" />
                    </slide>

                    <template #addons>
                      <navigation />
                      <pagination />
                    </template>
                  </carousel>
                </div>
                <div class="col-6">
                  <div class="tab-box">
                    <ul class="tab-head nav nav-tabs">
                      <li
                        class="nav-item"
                        data-id="tc1"
                        @click="tab2('tc1')"
                        v-bind:class="[bcurindex=='tc1' ? 'nav-item active show':'nav-item']"
                      >
                        <a data-toggle="tab" class="nav-link" aria-expanded="true">
                          <div class="lang" v-html="lan.building_base"></div>
                        </a>
                      </li>
                      <li
                        class="nav-item"
                        data-id="tc2"
                        @click="tab2('tc2')"
                        v-bind:class="[bcurindex=='tc2' ? 'nav-item active show':'nav-item']"
                      >
                        <a data-toggle="tab" class="nav-link" aria-expanded="true">
                          <div class="lang" v-html="lan.building_ext"></div>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-content" style="width:100%;background:#fff">
                    <div
                      class="embed-responsive-16by9 tab-pane show active tc1"
                      v-if="bcurindex=='tc1'"
                    >
                      <div class="table-rep-plugin">
                        <div class data-pattern="priority-columns">
                          <table class="table table-borderless">
                            <tbody>
                              <tr
                                v-for="(value, keyName) in BuildingInfo"
                                :key="keyName"
                                v-show="checkKeys.includes(keyName)"
                              >
                                <td
                                  v-if="keyName=='PublicTransportationMetro_1'"
                                >{{lan['PublicTransportationMetro']}}</td>
                                <td v-if="keyName=='FlrsAbvGrnd'">{{lan['FlrsAbvBlwGrnd']}}</td>

                                <td
                                  v-if="keyName!='FlrsAbvGrnd' && keyName!='PublicTransportationMetro_1'"
                                >{{lan[keyName]}}</td>
                                <th v-if="keyName=='PublicTransportationMetro_1'">
                                  {{BuildingInfo['PublicTransportationMetro_1']}} {{(BuildingInfo['building_ext']['最寄り駅徒歩時間(分)1']!=undefined && BuildingInfo['building_ext']['最寄り駅徒歩時間(分)1']!=null ? '徒歩'+BuildingInfo['building_ext']['最寄り駅徒歩時間(分)1']+'分':'')}}
                                  <br />
                                  {{BuildingInfo['PublicTransportationMetro_2']}} {{(BuildingInfo['building_ext']['最寄り駅徒歩時間(分)2']!=undefined && BuildingInfo['building_ext']['最寄り駅徒歩時間(分)2']!=null ? '徒歩'+BuildingInfo['building_ext']['最寄り駅徒歩時間(分)2']+'分':'')}}
                                  <br />
                                  {{BuildingInfo['PublicTransportationMetro_3']}} {{(BuildingInfo['building_ext']['最寄り駅徒歩時間(分)3']!=undefined && BuildingInfo['building_ext']['最寄り駅徒歩時間(分)3']!=null ? '徒歩'+BuildingInfo['building_ext']['最寄り駅徒歩時間(分)3']+'分':'')}}
                                </th>
                                <th
                                  v-if="keyName=='Typical_Minimum_FloorSize'"
                                >{{formart_money(BuildingInfo['Typical_Minimum_FloorSize'])}}坪 / {{formart_money(BuildingInfo['Typical_Minimum_FloorSize_SqM'])}}m2</th>
                                <th
                                  v-if="keyName=='TotalGFA_SqM'"
                                >{{formart_money(BuildingInfo['TotalGFA'])}}坪 / {{formart_money(BuildingInfo['TotalGFA_SqM'])}}m2</th>
                                <th
                                  v-if="keyName=='FlrsAbvGrnd'"
                                >{{lan['FlrsAbvBlwGrndValue'].replace('{abv}',BuildingInfo['FlrsAbvGrnd']).replace('{blw}',BuildingInfo['FlrsBlwGrnd'])}}</th>
                                <th
                                  v-if="keyName!='FlrsAbvGrnd' && keyName!='TotalGFA_SqM' && keyName!='Typical_Minimum_FloorSize' && keyName!='PublicTransportationMetro_1'"
                                >{{formart_date(value)+(keyName=='BuildingName'?'('+BuildingInfo.BuildingID+')':'' )}}</th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- end .table-responsive -->
                      </div>
                    </div>
                    <div
                      class="embed-responsive-16by9 tab-pane show active tc2"
                      v-if="bcurindex=='tc2'"
                    >
                      <div class="table-rep-plugin">
                        <div class data-pattern="priority-columns">
                          <table class="table table-borderless">
                            <tbody>
                              <tr
                                v-for="(value, keyName) in BuildingInfo.building_ext"
                                v-show="!checkKeys_ext.includes(keyName)"
                                :key="keyName"
                              >
                                <td>{{keyName}}</td>
                                <th>{{value}}{{(keyName=='エレベーター数' ?'基':'')}}</th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- end .table-responsive -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <google-map v-if="!isLoading" :address="BuildingInfo.Address" :reload="reloadCount" />
              <div class="row" v-else>
                <div class="col-6">
                  google-map
                  <div class="page-title-box">
                    <h4 class="page-title">
                      <div class="lang" v-html="lan.google_map"></div>
                    </h4>
                  </div>
                  <div class="card-box mb-2">
                    <!-- map -->
                    <div class="d-flex justify-content-center p-5">
                      <div class="spinner-border m-12" role="status">
                        <span class="sr-only">
                          <div class="lang" v-html="lan.loading"></div>...
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="page-title-box">
                    <h4 class="page-title">
                      <div class="lang" v-html="lan.google_street_view"></div>
                    </h4>
                  </div>
                  <div class="card-box mb-2">
                    <!-- streetview -->
                    <div class="d-flex justify-content-center p-5">
                      <div class="spinner-border m-12" role="status">
                        <span class="sr-only">
                          <div class="lang" v-html="lan.loading"></div>...
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="page-title-box">
                    <h4 class="page-title">{{$language('rader_chart')}}</h4>
                  </div>
                  <div class="card-box mb-2">
                    <div class>
                      <apexchart
                        type="radar"
                        height="500px"
                        width="100%"
                        :options="options"
                        :series="series"
                      ></apexchart>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="page-title-box">
                    <h4 class="page-title">
                      <div class="lang" v-html="lan.property_features"></div>
                    </h4>
                  </div>
                  <div class="card-box mb-2">
                    <!-- rangeslider -->

                    <div v-if="!isLoading3">
                      <div class="mb-3" v-for="(obj, num) in rangeKeys" :key="num">
                        <div v-if="jsonLang[lang][obj]">
                          <h4 class="header-title mb-1">{{ jsonLang[lang][obj] }}
                          <span v-if="obj != 'feature_1' && obj != 'feature_3'">:{{features_obj[obj]}}</span>
                          </h4>

                          <range-slider
              
                            :id="obj"
                            :from="(obj == 'feature_1' || obj == 'feature_3')?(features_obj[obj]) : 0"
                            
                            @updateRange="UpdateRange"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center p-5" v-else>
                      <div class="spinner-border m-12" role="status">
                        <span class="sr-only">
                          <div class="lang" v-html="lan.loading"></div>...
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="page-title-box">
                    <h4 class="page-title">
                      他の人のコメント
                      <span>({{extendParams.extended_memo_others.length}})</span>
                    </h4>
                  </div>
                  <div class="card-box mb-2">
                    <!-- other comments -->
                    <div v-if="!isLoading3">
                      <div class="carousel" v-if="extendParams.extended_memo_others.length > 0">
                        <div class="d-flex other-comments-container">
                          <button
                            type="button"
                            class="btn btn-light other-control other-control-prev"
                            @click.prevent.stop="OtherComments('prev')"
                            v-if="extendParams.extended_memo_others.length > 1"
                          >
                            <i class="mdi mdi-chevron-double-left" aria-hidden="true"></i>
                          </button>
                          <div class="other-item card-body" :id="'memo-' + otherCount">
                            <div
                              v-if="extendParams.extended_memo_others[otherCount].memo"
                              v-html="extendParams.extended_memo_others[otherCount].memo"
                            ></div>
                            <div v-else class="text-secondary">コメント未入力</div>
                            <span
                              style="color:#ccc;position:absolute;right:5px;bottom:5px;"
                            >日付：{{extendParams.extended_memo_others[otherCount].updated_at}}</span>
                          </div>
                          <button
                            type="button"
                            class="btn btn-light other-control other-control-next"
                            @click.prevent.stop="OtherComments('next')"
                            v-if="extendParams.extended_memo_others.length > 1"
                          >
                            <i class="mdi mdi-chevron-double-right" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>
                      <div v-else>
                        <p class="text-muted">{{$language('コメントがありません')}}</p>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center p-5" v-else>
                      <div class="spinner-border m-12" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="page-title-box">
                    <h4 class="page-title">{{$language('コメント')}}</h4>
                  </div>
                  <div class="card-box mb-2">
                    <!-- comment -->
                    <div v-if="!isLoading3">
                      <textarea class="form-control" rows="10" v-model="extendParams.extended.memo"></textarea>
                    </div>
                    <div class="d-flex justify-content-center p-5" v-else>
                      <div class="spinner-border m-12" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div
                  class="col-12 btn-toolbar justify-content-end align-items-center row pr-0 pl-3"
                >
                  <div
                    class="col-11 mb-0 alert alert-primary"
                    v-if="saveAlert === 1"
                  >{{$language('ご意見有難う御座います。内容を確認してシステム上に反映します。')}}</div>
                  <div class="col-11 mb-0 alert alert-danger" v-if="saveAlert === 9">{{$language('保存に失敗しました')}}</div>
                  <div class="col-1 pr-0">
                    <button type="button" class="btn btn-primary" @click="SaveParams">{{$language('save')}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { inject } from "vue";
//import ListArticleLease from '@/components/ListArticleLease.vue'
import router from "@/router";
import Headers from "@/components/Headers.vue";
import GoogleMap from "@/components/GoogleMap.vue";

import RangeSlider from "@/components/RangeSlider.vue";
//import ListCorpTable from '@/components/ListCorpTable.vue'
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

function getareaurl(address) {
  var areadata = {
    tokyo_Grade: { jp: "東京都", en: "Tokyo GradeA", code: "tky23" },
    osaka: { jp: "大阪", en: "Osaka", code: "osk" },
    nagoya: { jp: "名古屋", en: "Nagoya", code: "ngy" },
    yokohama: { jp: "横浜", en: "Yokohama", code: "ykh" },
    saitama: { jp: "さいたま", en: "Saitama", code: "911" },
    sapporo: { jp: "札幌", en: "Sapporo", code: "8010100" },
    sendai: { jp: "仙台", en: "Sendai", code: "8040100" },
    kanazawa: { jp: "金沢", en: "Kanazawa", code: "8170100" },
    kyoto: { jp: "京都", en: "Kyoto", code: "8260100" },
    kobe: { jp: "神戸", en: "Kobe", code: "8280100" },
    hiroshima: { jp: "広島", en: "Hiroshima", code: "8340100" },
    fukuoka: { jp: "福岡", en: "Fukuoka", code: "8400100" },
  };
  var new_area = "tokyo_Grade,nagoya,osaka";
  for (var d in areadata) {
    if (address.indexOf(areadata[d].jp) > -1) {
      console.log(d);
      if (new_area.indexOf(d) > -1) {
        return (
          "https://cbredata.com/jp/research_report_tokyo_grade.php?showtype=cbreai&area=" +
          d +
          "&id_token=" +
          localStorage.getItem("id_token")
        );
      } else {
        return (
          "https://cbredata.com/jp/research_report" +
          (d == "nagoya" || d == "osaka" ? "_grade" : ".old") +
          ".php?showtype=cbreai&area=" +
          d +
          "&id_token=" +
          localStorage.getItem("id_token")
        );
      }
    } else {
      return "";
    }
  }
}
export default {
  name: "CorpDetail",
  components: {
    //ListArticleLease,
    GoogleMap,
    RangeSlider,
    Headers,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    //ListCorpTable,
  },
  data() {
    const { currentRoute } = router;
    return {
      tolink: "/" + currentRoute.value.query.tolink,
      curlink: encodeURIComponent(window.location.href.split("#")[1]),
      LeaseInfo: {},
      viewParamsHistory: {
        name: "corp_history",
        apiUrl:
          "/v1/getListByCropNum?cropNum=" + currentRoute.value.query.dataNumber,
        paramKey: "ID",
      },
      BuildingID: currentRoute.value.query.BuildingID,
      BuildingID_to: 198117,
      LeaseID: currentRoute.value.query.LeaseID,
      BuildingInfo: {},
      BuildingPic: [],
      BuildingInfo_to: {},
      BuildingPic_to: [],
      // company
      viewParams_company: {
        name: "CorpReference",
        apiUrl: "/v1/corps_reference/",
        articleView: "CorpReferenceArticle",
        articleParamId: "id",
        dataType: 100,
      },
      viewParams: {
        name: "build1",
        apiUrl: "/v1/properties1/" + currentRoute.value.query.BuildingID,
        apiUrl_to: "/v1/properties1/198117",
        paramKey: "BuildingID",
      },
      corp_num: "",
      viewParamsOffice: {
        name: "corp_offices2",
        apiUrl: "/v1/corpsoffices/100?cid=100&corp_num=1",
        paramKey: "ID",
        randIndex: 0,
      },
      raderChartURL:
        process.env.VUE_APP_PRP_CHART_URL_BASE +
        currentRoute.value.query.BuildingID +
        ".html",
      jsonObj: [Object],
      jsonObjTypes: [Object],
      jsonObj_to: [Object],
      jsonObjTypes_to: [Object],
      isLoading: true,
      jsonErr: false,
      articleTableEdit: false, // true = edit table
      extendParams: {
        extended: {},
        extended_memo_self: {},
        extended_memo_others: [],
      },
      extendParams_to: {
        extended: {},
        extended_memo_self: {},
        extended_memo_others: [],
      },
      rangeKeys_to: [],
      rangeKeys: [],
      jsonLang: {},
      lang: "ja",
      saveAlert: 0, // 保存結果
      otherCount: 0,
      reloadCount: 0, // map再読み込み用
      reloadCount_to: 0, // map再読み込み用
      editAlert: false,
      options: {},
      options_to: {},
      series: [],
      series_to: [],
      isLoading3: true,
      curindex: "tc1",
      ccurindex: "tc1",
      bcurindex: "tc1",
      bcurindex_to: "tc1",
      cur_lan: localStorage.getItem("cur_lan"),
      lan: inject("global").language_data,
      lan_help: inject("global").language_help_data,
      isattr: 0,
      isattr_to: 0,
      checkKeys: [
        "Address",
        "Typical_Minimum_FloorSize",
        "TotalGFA_SqM",
        "BuildingName",
        "BuildingSectorsJA",
        "CompletionDate",
        "Grade",
        "PublicTransportationMetro_1",
        //'PublicTransportationMetro_2',
        //'PublicTransportationMetro_3'
      ],
      checkKeys_ext: [
        "最寄り駅徒歩時間(分)1",
        "最寄り駅徒歩時間(分)2",
        "最寄り駅徒歩時間(分)3",
      ],
      market_url: "",
      features_obj: {
        feature_1: 49,
        feature_2: 33,
        feature_3: 80,
        feature_4: 100,
        feature_5: 0,
        feature_6: 0,
        feature_7: 0,
        feature_8: 0,
      },
    };
  },
  mounted() {
    //let language_data=JSON.parse(localStorage.getItem('language_data'));
    //this.lan=language_data
    this.getLeaseInfo();
    this.getBuildingPic();
    this.getBuildingInfo();
    this.getBuildingPic_to();
    this.getBuildingInfo_to();
    this.getFeaturesObj();
  },
  methods: {
    getFeaturesObj(){
      axios.get(process.env.VUE_APP_API2_URL_BASE +`/get_api_tab2_features?building_id=${this.BuildingID}`).then(res=>{
        console.log("返回消息",res);
        this.features_obj = res.data.data;
      })
    },
    goto(corp_number) {
      console.log("corp_number" + corp_number);
    },
    formart_money: function (num) {
      var result = "",
        counter = 0;
      num = (num || 0).toString();
      var numa = num.split(".");
      var d = 0;
      if (numa[1] != undefined) {
        d = numa[1].length + 1;
      }

      for (var i = num.length - d - 1; i >= 0; i--) {
        counter++;
        result = num.charAt(i) + result;
        if (!(counter % 3) && i != 0 && num.charAt(i - 1) != "-") {
          result = "," + result;
        }
      }
      return d > 0 ? result + "." + numa[1] : result;
    },
    formart_date: function (num) {
      return typeof num == "string" ? num.replace(" 00:00:00", "") : num;
    },

    ToggleArticleTable: function (opt) {
      this.articleTableEdit = !this.articleTableEdit;
      if (opt === "save") {
        document.getElementById("article_table").scrollTo({
          top: 0,
        });
        window.scrollTo({
          top: 0,
        });
        this.editAlert = true;
      } else {
        this.editAlert = false;
      }
    },
    UpdateData: function (data) {
      this.jsonObj = data;
      this.reloadCount++;
    },

    getBuildingInfo_to() {
      var that = this;
      axios
        .get("/v1/building_info?BuildingID=" + this.BuildingID_to)
        .then((dd) => {
          that.BuildingInfo_to = dd.data;
          console.log(that.BuildingInfo_to);
          that.isLoading = false;
          //that.market_url=getareaurl(that.BuildingInfo_to.Address);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getBuildingInfo() {
      var that = this;
      axios
        .get("/v1/building_info?BuildingID=" + this.BuildingID)
        .then((dd) => {
          that.BuildingInfo = dd.data;
          console.log(that.BuildingInfo);
          that.isLoading = false;
          that.market_url = getareaurl(that.BuildingInfo.Address);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getLeaseInfo() {
      var that = this;
      axios
        .get("/v1/lease_info?LeaseID=" + this.LeaseID)
        .then((dd) => {
          that.LeaseInfo = dd.data;
          console.log(dd.data.contactInfo.corp_num);
          if (
            dd.data.contactInfo.corp_num != undefined &&
            dd.data.contactInfo.corp_num != null &&
            dd.data.contactInfo.corp_num != ""
          ) {
            that.corp_num = dd.data.contactInfo.corp_num;
            //that.viewParamsOffice.apiUrl='/v1/corpsoffices/100?cid=100&corp_num='+ that.corp_num;
            console.log(that.corp_num);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getBuildingPic() {
      var that = this;
      axios
        .get("/v1/building_pic?BuildingID=" + this.BuildingID)
        .then((dd) => {
          that.BuildingPic = dd.data.data;
          console.log(that.BuildingPic);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getBuildingPic_to() {
      var that = this;
      axios
        .get("/v1/building_pic?BuildingID=" + this.BuildingID_to)
        .then((dd) => {
          that.BuildingPic_to = dd.data.data;
          console.log(that.BuildingPic_to);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    SaveParams: function () {
      this.saveAlert = 0;
      axios
        .put(this.viewParams.apiUrl + "/extended", {
          extended: this.extendParams.extended,
          email: localStorage.getItem("email"),
          id_token: localStorage.getItem("id_token"),
        })
        .then(() => {
          this.saveAlert = 1;
        })
        .catch((error) => {
          this.saveAlert = 9;
          console.log(error);
        });
    },
    SaveParams_to: function () {
      this.saveAlert = 0;
      axios
        .put(this.viewParams.apiUrl + "/extended", {
          extended: this.extendParams_to.extended,
          email: localStorage.getItem("email"),
          id_token: localStorage.getItem("id_token"),
        })
        .then(() => {
          this.saveAlert = 1;
        })
        .catch((error) => {
          this.saveAlert = 9;
          console.log(error);
        });
    },
    RangeKeysFilter: function () {
      let Ary = Object.keys(this.extendParams.extended);
      //'feature_'からはじまるキー
      this.rangeKeys = Ary;
      this.rangeKeys = Ary.filter((key) => key.startsWith("feature_"));
      console.log("排名:", this.rangeKeys);
      var cats = [];
      var dt = [];
      for (var d in this.rangeKeys) {
        cats.push(this.jsonLang[this.lang][this.rangeKeys[d]]);
        dt.push(this.extendParams.extended[this.rangeKeys[d]]);
      }
      this.series = [
        {
          name: "物件の特徴",
          data: dt,
        },
      ];
      this.options = {
        chart: {
          height: 500,
          type: "radar",
        },

        xaxis: {
          categories: cats,
        },
      };
    },
    RangeKeysFilter_to: function () {
      let Ary = Object.keys(this.extendParams_to.extended);
      //'feature_'からはじまるキー
      // this.rangeKeys_to = Ary
      this.rangeKeys_to = Ary.filter((key) => key.startsWith("feature_"));
      console.log("排序数据：", this.rangeKeys_to);
      var cats = [];
      var dt = [];
      for (var d in this.rangeKeys_to) {
        cats.push(this.jsonLang[this.lang][this.rangeKeys_to[d]]);
        dt.push(this.extendParams_to.extended[this.rangeKeys_to[d]]);
      }

      this.series_to = [
        {
          name: "物件の特徴",
          data: dt,
        },
      ];
      this.options_to = {
        chart: {
          height: 500,
          type: "radar",
        },

        xaxis: {
          categories: cats,
        },
      };
      console.log("charts_to");
    },
    UpdateRange(res) {
      this.extendParams.extended[res.id] = res.data.from;
    },
    UpdateRange_to(res) {
      this.extendParams_to.extended[res.id] = res.data.from;
    },
    OtherComments: function (key) {
      if (key === "next") {
        if (this.extendParams.extended_memo_others[this.otherCount + 1]) {
          this.otherCount++;
        } else {
          this.otherCount = 0;
        }
      } else {
        if (this.otherCount !== 0) {
          this.otherCount--;
        } else {
          this.otherCount = this.extendParams.extended_memo_others.length - 1;
        }
      }
    },
    tab: function (tid) {
      this.curindex = tid;
      if (tid == "tc2" && this.isattr == 0) {
        this.loadattr();
      }
      if (tid == "tc6" && this.isattr_to == 0) {
        this.loadattr_to();
      }
    },
    tab2: function (tid) {
      this.bcurindex = tid;
    },
    tab2_to: function (tid) {
      this.bcurindex_to = tid;
    },
    loadattr_to() {
      var that = this;
      if (this.isattr_to == 0) {
        this.isLoading4 = true;
        this.jsonLang = require("@/assets/languages/ListArticle.json");
        axios
          .get(this.viewParams.apiUrl_to)
          .then((response) => {
            that.isattr_to = 1;
            that.jsonObj_to = response.data.data;
            that.jsonObjTypes_to = response.data.types;
            if (
              response.data.extended != null &&
              response.data.extended.length > 0
            ) {
              that.extendParams_to.extended = response.data.extended;
            } else {
              that.extendParams_to.extended = {
                Address:
                  that.jsonObj.address == undefined
                    ? that.jsonObj.Address
                    : that.jsonObj.address,
                BuildingID: that.jsonObj.BuildingID,
                feature_1: 0,
                feature_2: 0,
                feature_3: 0,
                feature_4: 0,
                feature_5: 0,
                feature_6: 0,
                feature_7: 0,
                feature_8: 0,
              };
            }
            if (response.data.extended_memo_self != null) {
              that.extendParams_to.extended.memo =
                response.data.extended_memo_self.memo;
            } else {
              that.extendParams_to.extended.memo = null;
            }
            if (response.data.extended_memo_others.length > 0) {
              that.extendParams_to.extended_memo_others =
                response.data.extended_memo_others;
            }
            that.RangeKeysFilter_to();

            that.isLoading4 = false;
          })
          .catch(
            (error) => (
              console.log("tab初始化失败:", error),
              (that.jsonErr = true),
              (that.isLoading4 = false)
            )
          );
      }
    },
    loadattr() {
      var that = this;
      if (this.isattr == 0) {
        this.isLoading3 = true;
        this.jsonLang = require("@/assets/languages/ListArticle.json");
        console.log("请求网址:", this.viewParams.apiUrl);
        axios
          .get(this.viewParams.apiUrl)
          .then((response) => {
            console.log("第二标签数据", response);
            that.isLoading3 = false;
            that.isattr = 1;
            that.jsonObj = response.data.data;
            that.jsonObjTypes = response.data.types;
            if (
              response.data.extended != null &&
              response.data.extended.length > 0
            ) {
              that.extendParams.extended = response.data.extended;
            } else {
              that.extendParams.extended = {
                Address:
                  that.jsonObj.address == undefined
                    ? that.jsonObj.Address
                    : that.jsonObj.address,
                BuildingID: that.jsonObj.BuildingID,
                feature_1: 0,
                feature_2: 0,
                feature_3: 0,
                feature_4: 0,
                feature_5: 0,
                feature_6: 0,
                feature_7: 0,
                feature_8: 0,
              };
            }
            if (response.data.extended_memo_self != null) {
              that.extendParams.extended.memo =
                response.data.extended_memo_self.memo;
            } else {
              that.extendParams.extended.memo = null;
            }
            if (response.data.extended_memo_others.length > 0) {
              that.extendParams.extended_memo_others =
                response.data.extended_memo_others;
            }
            console.log(that.extendParams.extended);
            that.RangeKeysFilter();
          })
          .catch(
            (error) => (
              console.log(error),
              (that.jsonErr = true),
              (that.isLoading3 = false)
            )
          );
      }
    },
    backListener() {
      this.$refs.myEnergy.destroySelf();
    },
  },
  beforeUnmount() {
    window.removeEventListener("pagehide", this.backListener);
  },
};
</script>
<style lang="scss">
.btn-back {
  position: absolute;
  right: 0;
  top: 20px;
  z-index: 255;
}

.build-article .card-box {
  max-height: 1460px;
  overflow-y: auto;
}

.build-article .row > div {
  display: flex;
  flex-direction: column;
}

.build-article .card-box {
  flex: 1;
}

// rader-chart
.build-article .embed-responsive-1by1 {
  max-height: 550px;
}

.build-article .row .btn-toolbar {
  flex-direction: row;
}

.other-comments-container {
  position: relative;
}

.other-item {
  text-align: left;
  display: block;
  flex: 1;
  height: 230px;
  overflow-y: auto;
  padding: 0.5rem 1rem;
  word-break: break-all;
}

.other-control {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1.5rem 0.25rem;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.15s;
}

.other-control-prev {
  left: -1.5rem;
}

.other-control-next {
  right: -1.5rem;
}
.piclist .carousel {
  height: 640px;
  width: 90%;
}
.carousel__prev,
.carousel__next {
  background: #012a2d;
}
.carousel__pagination-button--active {
  color: #012a2d;
  background: #012a2d;
}
.carousel__slide {
  margin: 0 auto;
  width: 100% !important;
}
.table-borderless tr {
  border-bottom: 1px solid #ddd;
}
.carousel__slide img {
  max-width: 100%;
  max-height: 100%;
}
.responsive-table-plugin tbody th {
  width: 25%;
  font-weight: bolder !important;
}
.market_url {
  width: 100%;
  height: 800px;
  border: 0;
}
</style>